import React, { useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function App() {
  return (
    <div className="App">
      <ResetPasswordForm />
    </div>
  );
}
