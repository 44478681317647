import React, { useEffect, useState } from "react";
import { Button, Image, Form } from "react-bootstrap";

export default function ResetPasswordForm() {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpCode, setShowOtpCode] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [tokenUUID, setTokenUUID] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailDisabled, setEmailDisabled] = useState(false);

  useEffect(() => {
    setSubmitMessage("Send Forgot Password Request");

    const email = new URLSearchParams(window.location.search).get("email");
    if (email) {
      setEmail(email);
      setEmailDisabled(true);
      submit(email, null, null);
    }
  }, []);

  const handleSubmit = async (e) => {
    setError(null);
    setMessage(null);

    e.preventDefault();

    const email = e.target?.email?.value;
    const otpCode = e.target?.otpCode?.value;
    const password = e.target?.password?.value;
    const confirmPassword = e.target?.confirmPassword?.value;

    if (confirmPassword != password) {
      setError("Password did not match");
      return;
    }

    submit(email, otpCode, password);
  };

  const submit = async (email, otpCode, password) => {
    setError(null);
    setMessage(null);

    if (!showOtpCode) {
      setMessage(
        "We will send the authroization code to the provided email if we found a matching record."
      );

      setIsLoading(true);
      setShowOtpCode(true);
      setEmailDisabled(true);
      setSubmitMessage("Submit With OTP");

      /**
       * Call to backend to generate otp code to email
       */
      await fetch(
        `https://connect.uat.directintegrate.com/cgp/cgp-customer/v1/customer/forgot-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: email,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          /**
           * Store token identifier here
           */
          setTokenUUID(response?.data?.tokenUUID);
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setIsLoading(true);

      await fetch(
        `https://connect.uat.directintegrate.com/cgp/cgp-customer/v1/customer/reset-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            tokenUUID,
            otpCode,
            password,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          setMessage(response.data);
        })
        .catch((error) => {
          setError(error);
        });
    }

    setIsLoading(false);
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div className="text-center" style={{ marginBottom: "32px" }}>
        <Image
          src="https://media.directintegrate.com/directintegrate/logo.png"
          fluid
          width={240}
        />
      </div>

      <Form.Group className="mb-3" controlId="email-input">
        <Form.Label>Email address</Form.Label>
        <br />
        <Form.Control
          type="email"
          placeholder="name@example.com"
          disabled={emailDisabled}
          name="email"
          value={email}
          required
        />
      </Form.Group>
      {showOtpCode && (
        <span>
          <Form.Group className="mb-3" controlId="otp-code-input">
            <Form.Label>OTP Code</Form.Label>
            <br />
            <Form.Control type="text" name="otpCode" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password-input">
            <Form.Label>Password</Form.Label>
            <br />
            <Form.Control type="password" name="password" required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirm-password-input">
            <Form.Label>Confirm Password</Form.Label>
            <br />
            <Form.Control type="password" name="confirmPassword" required />
          </Form.Group>
        </span>
      )}

      <Button
        disabled={isLoading}
        id="submit"
        type="submit"
        style={{ background: "#0b194e" }}
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            submitMessage
          )}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && (
        <div
          className="text-center"
          id="notification-message"
          style={{
            marginTop: "16px",
            color: "black",
            fontSize: "75%",
            background: "#b2dfdb",
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          {message}
        </div>
      )}

      {error && (
        <div
          className="text-center"
          id="notification-message"
          style={{
            marginTop: "16px",
            color: "white",
            fontSize: "75%",
            background: "#ef9a9a",
            padding: "16px",
            borderRadius: "16px",
          }}
        >
          {error}
        </div>
      )}
    </Form>
  );
}
